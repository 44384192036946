.projects {
  &-illutration {
    max-width: 48rem;

    &-container {
      margin: 4rem;
      text-align: center;
    }
  }

  &-buttons-container {
    gap: 4rem;
    display: flex;
    margin-top: 4rem;
    justify-content: center;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &-item {
    border-radius: 0.8rem;
    gap: 4rem;
    display: flex;
    padding: 2rem;
    margin: 1rem 2rem;
    transition: background 0.3s;
    box-shadow: inset 0 -4px 10px 1px rgba(0, 0, 0, 0.2),
      inset 0 4px 10px 1px rgba(255, 255, 255, 0.2),
      0 0 4px 1px rgba(0, 0, 0, 0.3);
    background: linear-gradient(
      90deg,
      var(--secondary-background-dark) 50%,
      var(--primary-background) 50%
    );

    &:nth-of-type(even) {
      flex-direction: row-reverse;
      text-align: left;
      background: linear-gradient(
        90deg,
        var(--primary-background) 50%,
        var(--secondary-background-dark) 45%
      );
    }

    &:hover &-thmbnail {
      transform: scale(1.063, 1.14) translateY(1px);
    }

    &-thmbnail {
      width: 100%;
      height: auto;
      transition: transform 1s;

      &-container {
        width: 50%;
        text-align: center;
      }
    }

    &-details {
      &-container {
        width: 50%;
      }

      &-tags {
        &-container {
          margin: 2rem 0 1rem;
          display: flex;
          gap: 1rem;
        }

        &-item {
          display: inline-block;
          padding: 0.4rem 0.8rem;
          border-radius: 4px;
          font-size: 1.2rem;
          background: var(--secondary-background-dark);
          color: var(--white);
        }
      }

      &-title {
        font-size: 2.4rem;
        margin-bottom: 2rem;
        user-select: none;
      }

      &-description {
        font-size: 1.6rem;
        line-height: 2.8rem;
      }

      &-buttons-container {
        gap: 1.6rem;
        display: flex;
        flex-wrap: wrap;
        align-self: flex-end;
        margin-top: 2.8rem;

        & > *:last-child {
          transform: scale(0.85, 0.8) translateY(-0.6rem);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .projects {
    &-item {
      gap: 2rem;
      padding: 3.2rem;
      flex-direction: column !important;
      background: linear-gradient(
        180deg,
        var(--secondary-background),
        var(--primary-background)
      ) !important;

      &-thmbnail {
        padding: 0rem;
        box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.3);

        &-container {
          width: 100%;
          text-align: center;
        }
      }

      &-details {
        &-container {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .projects {
    &-item {
      padding: 1.6rem;
      margin: 1rem 2.8rem;

      &-thmbnail {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .projects {
    &-item {
      &-details-buttons-container {
        justify-content: center;
      }
    }
  }
}
