// margins
.m-1 {
  margin: 1px !important; 
}
.m-2 {
  margin: 2px !important; 
}
.m-4 {
  margin: 4px !important; 
}
.m-6 {
  margin: 6px !important; 
}
.m-8 {
  margin: 8px !important; 
}
.m-10 {
  margin: 10px !important; 
}
.m-12 {
  margin: 1.2rem !important; 
}
.m-14 {
  margin: 1.4rem !important; 
}
.m-16 {
  margin: 1.6rem !important; 
}
.m-20 {
  margin: 2rem !important; 
}
.m-24 {
  margin: 2.4rem !important; 
}
.m-28 {
  margin: 2.8rem !important; 
}
.m-32 {
  margin: 3.2rem !important; 
}
.my-1 {
  margin: 1px 0 !important; 
}
.my-2 {
  margin: 2px 0 !important; 
}
.my-4 {
  margin: 4px 0 !important; 
}
.my-6 {
  margin: 6px 0 !important; 
}
.my-8 {
  margin: 8px 0 !important; 
}
.my-10 {
  margin: 1rem 0 !important; 
}
.my-12 {
  margin: 1.2rem 0 !important; 
}
.my-14 {
  margin: 1.4rem 0 !important; 
}
.my-16 {
  margin: 1.6rem 0 !important; 
}
.my-20 {
  margin: 2rem 0 !important; 
}
.my-24 {
  margin: 2.4rem 0 !important; 
}
.my-28 {
  margin: 2.8rem 0 !important; 
}
.my-32 {
  margin: 3.2rem 0 !important; 
}
.mt-1 {
  margin-top: 1px !important; 
}
.mt-2 {
  margin-top: 2px !important; 
}
.mt-4 {
  margin-top: 4px !important; 
}
.mt-6 {
  margin-top: 6px !important; 
}
.mt-8 {
  margin-top: 8px !important; 
}
.mt-10 {
  margin-top: 1rem !important; 
}
.mt-12 {
  margin-top: 1.2rem !important; 
}
.mt-14 {
  margin-top: 1.4rem !important; 
}
.mt-16 {
  margin-top: 1.6rem !important; 
}
.mt-20 {
  margin-top: 2rem !important; 
}
.mt-24 {
  margin-top: 2.4rem !important; 
}
.mt-28 {
  margin-top: 2.8rem !important; 
}
.mt-32 {
  margin-top: 3.2rem !important; 
}
.mt-36 {
  margin-top: 3.6rem !important; 
}
.mt-40 {
  margin-top: 4rem !important; 
}
.mt-48 {
  margin-top: 4.8rem !important; 
}
.mt-56 {
  margin-top: 5.6rem !important; 
}
.mt-64 {
  margin-top: 6.4rem !important; 
}
.mt-72 {
  margin-top: 7.2rem !important; 
}
.mt-78 {
  margin-top: 7.8rem !important; 
}
.mt-84 {
  margin-top: 8.4rem !important; 
}
.mt-90 {
  margin-top: 9.0rem !important; 
}
.mt-96 {
  margin-top: 9.6rem !important; 
}
.mx-1 {
  margin: 0 1px !important; 
}
.mx-2 {
  margin: 0 2px !important; 
}
.mx-4 {
  margin: 0 4px !important; 
}
.mx-6 {
  margin: 0 6px !important; 
}
.mx-8 {
  margin: 0 8px !important; 
}
.mx-10 {
  margin: 0 10px !important; 
}
.mx-12 {
  margin: 0 1.2rem !important; 
}
.mx-14 {
  margin: 0 1.4rem !important; 
}
.mx-16 {
  margin: 0 1.6rem !important; 
}
.mx-20 {
  margin: 0 2rem !important; 
}
.mx-24 {
  margin: 0 2.4rem !important; 
}
.mx-28 {
  margin: 0 2.8rem !important; 
}
.mx-32 {
  margin: 0 3.2rem !important; 
}

.align-center {
  text-align: center !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-transparent {
  color: transparent !important;
}

.justify-between {
  justify-content: space-between !important;
}

.primary-heading {
  font-size: 6rem;
  letter-spacing: 4px;
  position: relative;
  display: inline;
  user-select: none;
  // text-shadow: 2px 2px 2px var(--primary-text);
}

.highlight {
  position: absolute;
  display: inline-block;
  left: -.2rem;
  bottom: .8rem;
  width: 100%;
  height: .6rem;
  background-color: var(--secondary-background);
}

.anchor-img {
  height: 0;
  width: 0;
}

.wave-animation {
  animation: wave 1s ease-in-out .1s infinite both;
}

.moving-background {
  background-image: linear-gradient(to right, 
    var(--primary-text),  
    var(--secondary-background-dark),
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200%;
  background-position: left;
  animation: marquee-text 5s infinite ease-in-out both;
}

.toast {
  font-size: 18px !important;
}

.Toastify__toast--success {
  background: var(--toast-success) !important;
}

.Toastify__toast--error {
  background: var(--toast-error) !important;
}

.section {
  padding: 5rem 8rem;
  &__heading {
    font-size: 4rem;
    color: var(--secondary-background-dark);
    text-shadow: 1px 1px 3px rgba(0,0,0,.5),
      1px 1px 3px var(--white);
    padding: .8rem 0;
    padding-top: 1.5rem;

    &-info {
      font-size: 1.6rem;
      text-align: center;
    }
  }
}

.backdrop {
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  position: fixed; 
  background: rgba(0,0,0,.5);
}

@media screen and (max-width: 960px) {
  .section {
    padding: 5rem 2rem;
  }  
}

@media screen and (max-width: 600px) {
    .primary-heading {
      font-size: 4.6rem;
    }

    .highlight {
      bottom: .2rem;
    }
}

@media screen and (max-width: 460px) {
  .section {
    padding: 5rem 1rem;
  }

  .highlight {
    display: none;
  }
}