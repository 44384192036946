*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
}

:root {
  --white: #fff;
  --black: #000;
  --grey: grey;
  --toast-success: #3b885b;
  --toast-error: #ed4233;

  /* --secondary-background: #2ccab0; */
  /* --secondary-background: #67B9A4; */
  /* --secondary-background: #ff005c; */
  /* --secondary-background: #8ab4f8; */
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  max-width: 150rem;
  margin: 0 auto;
}

body {
  font-family: "Montserrat", sans-serif;
  background: var(--primary-background);
  color: var(--primary-text);
  /* padding: 2rem 8rem; */
  transition: background-color 0.5s, color 0.7s;
}

::selection {
  background-color: var(--secondary-background);
  color: var(--white);
}

mark {
  background: none;
  color: inherit;
  border-bottom: 3px solid var(--secondary-background);
}

button {
  border: none;
}

a {
  text-decoration: none;
}

img {
  user-select: none;
}

input,
select {
  font-family: inherit;
}

header {
  top: 0;
  z-index: 5;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  background: var(--primary-background);
  backface-visibility: hidden;
  transition: background-color 0.5s, color 0.1s;
  transition: transform 0.5s;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--grey);
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-background);
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-background-dark);
}

@media screen and (max-width: 600px) {
  html {
    font-size: 50%;
  }
}
