.services {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 4rem;
    margin-top: 6rem;
  }

  &-item {
    &-wrapper {
      padding: 1rem;
      border-radius: 20px;
      padding-bottom: 2rem;
      max-width: 32rem;
      text-align: center;
      color: var(--link-color);
      box-shadow: 0 0 20px -10px rgba(0,0,0,.3),
        inset 0 0 2px var(--secondary-background);
      transition: all .3s;

      &:nth-of-type(2) {
        transform: scale(1.03);
        border: 5px solid transparent;
        box-shadow: 0 0 4px 2px rgba(0,0,0,.2);
      }
      
      &:nth-of-type(2):hover {
        transform: scale(1.05);
        box-shadow: 0 0 4px 2px rgba(0,0,0,.2);
        border: 5px solid var(--secondary-background);
      }
    }

    &-img {
      width: 100%;
      height: 24rem;
      margin-bottom: 0rem;
    }

    &-title {
      margin: 1rem 0;
      font-size: 1.8rem;
    }

    &-tags{
      &-container {
        margin: 0 auto;
        gap: 1rem 2rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
      }

      &-item {
        padding: .6rem 1.4rem;
        font-size: 1.15rem;
        border-radius: 50px;
        color: var(--white);
        background: var(--secondary-background-dark);
      }
    }
  }
}