.nav {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 8rem;
    padding-top: 0.2rem;
    background: var(--primary-background);
    border-radius: 0 0 2rem 2rem;
    height: 70px;
  }

  &-logo-box {
    width: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-logo {
    width: 100%;
    cursor: pointer;
  }

  &-list {
    list-style: none;
    display: flex;
    align-items: center;

    &__item {
      display: inline-block;
      margin: 1rem;
      position: relative;
      // padding: .8rem 1rem;
      text-transform: capitalize;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        top: 0;
        width: 0%;
        height: 100%;
        z-index: -1;
        transition: width 0.2s;
      }

      &::before {
        left: 0;
        transform-origin: left;
        border-top: 2px solid var(--primary-text);
      }
      &::after {
        right: 0;
        transform-origin: right;
        border-bottom: 2px solid var(--primary-text);
      }

      &:not(.active-link):hover::before,
      &:not(.active-link):hover::after {
        width: 100%;
      }

      &:not(.active-link):focus-within::before,
      &:not(.active-link):focus-within::after {
        width: 100%;
      }

      & a {
        color: inherit;
        font-size: 1.6rem;
        padding: 0.8rem 1rem;
        text-decoration: none;
        display: inline-block;
        color: var(--link-color);

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.active-link {
  background: var(--secondary-background-dark);
  border-radius: 2px;

  &:hover,
  &:focus,
  &:focus-within {
    background: var(--secondary-background);
  }
}
.active-link a {
  color: var(--white) !important;
}

.sound_nav_item {
  font-size: 1.8rem;
  padding: 0.8rem;
  margin: 0.4rem;
  width: 20px;
  cursor: pointer;
}

.hamberger {
  &-active-link {
    background: var(--secondary-background-dark);
    color: var(--link-color);
    border-radius: 2px;

    & a {
      color: var(--white) !important;
    }

    &:hover,
    &:focus,
    &:focus-within {
      background: var(--secondary-background-dark);
      color: var(--black);
    }
  }

  &-container {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: flex-end;
    flex-direction: column;

    & span {
      display: inline-block;
      height: 6px;
      width: 4rem;
      border-radius: 50px;
      margin: 0.2rem 0;
      background: var(--secondary-background);

      &:first-of-type {
        width: 3.2rem;
      }
      &:last-of-type {
        width: 2.5rem;
      }
    }
  }

  &-nav {
    &-list {
      &-container {
        top: 100%;
        right: 10px;
        display: flex;
        overflow-y: auto;
        position: absolute;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: var(--primary-text);
        transition: height 0.3s, padding 0.3s;
        background: var(--secondary-background);
      }

      &__item {
        margin: 1rem;
        position: relative;
        display: inline-block;
        text-transform: capitalize;

        &::before,
        &::after {
          content: "";
          position: absolute;
          display: inline-block;
          top: 0;
          width: 0%;
          height: 1%;
          z-index: 0;
          transition: width 0.2s;
        }

        &::before {
          left: 0;
          transform-origin: left;
          border-top: 2px solid var(--primary-text);
        }
        &::after {
          right: 0;
          top: 100%;
          transform-origin: right;
          border-bottom: 2px solid var(--primary-text);
        }

        &:not(.hamberger-active-link):hover::before,
        &:not(.hamberger-active-link):hover::after {
          width: 100%;
        }

        &:not(.hamberger-active-link):focus-within::before,
        &:not(.hamberger-active-link):focus-within::after {
          width: 100%;
        }

        & a {
          color: inherit;
          font-size: 1.6rem;
          padding: 0.8rem 1rem;
          text-decoration: none;
          display: inline-block;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .nav {
    &-container {
      padding: 0rem 2rem;
      padding-top: 0.2rem;
    }
  }

  .nav-list__item {
    margin: 0.6rem;
    padding: 0.4rem 0.8rem;
  }
}

@media screen and (max-width: 720px) {
  .nav-list {
    display: none;
  }
}
