.theme-toggle {
  &-wrapper {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    cursor: pointer;
    font-size: 3rem;
    border-radius: 50%;
    user-select: none;
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
  }
}

.container {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 50px;
  height: 55px;
  border-radius: 15px;
  z-index: 100;
}

.inner {
  height: 55%;
  width: 38%;
  border-radius: 8px;
}

.switch {
  height: 75%;
  width: 60%;
  border-radius: 4px;
  cursor: pointer;
}

#switch {
  display: none;
}

#switch:checked + .switch.dark {
  background: #1da1f2  !important;
  box-shadow: inset 0 -4px 1px rgba(244, 244, 244, 0.1), inset 0px 5px 0 #1da1f2,
    inset 0px 5.5px 0 white, inset 0px 12px rgba(0, 0, 0, 0.04),
    0 0 2px -1px black;
}

.container.dark {
  background-image: linear-gradient(to bottom right, #bababa, #3d3d43);
  box-shadow: inset 0px 1px 4px white,
    inset 0px -8px 5px rgba(81, 81, 81, 0.9), 
    0 10px 10px rgba(0, 0, 0, 0.2),
    0 0 2px rgba(0, 0, 0, 0.3);
}

.inner.dark {
  background: #616161;
}

.container.light {
  background: white;
  box-shadow: inset 0px 1px 4px white,
    inset 0px -8px 5px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.15),
    0 0 2px rgba(0, 0, 0, 0.2);
}

.inner.light {
  background: white;
}

.switch.light {
  background: #bfbfbf;
  box-shadow: inset 0 2px 1px rgba(255, 255, 255, 0.4),
    inset 0 -6px 2px rgba(81, 81, 81, 0.6), inset 0 -7px 1px white;
}
