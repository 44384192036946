.home {
  margin-top: 15rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 8rem;
  padding: 0rem 8rem;

  &-dev {
    &-intro {
      font-size: 2.6rem;
      margin-left: .5rem;

      & span.name {
        font-size: 3.6rem;
      }
    }

    &-info {
      margin-top: 2rem;
    }

    &-work-des {
      &-wrapper {
        min-height: 2.6rem;
      }

      font-size: 2rem;
      margin-right: 4px;
      display: inline-block;
    }

    &-buttons-container {
      margin: 2rem 0;
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  &-illustration {
    position: relative;
    // background: red;
    z-index: -1;
    & img {
      width: 110%;
      animation: bounce 2s infinite both;
    }

    &-shadow {
      position: absolute;
      display: block;
      width: 60%;
      height: 20px;
      bottom: -10%;
      left: 30%;
      z-index: -1;
      border-radius: 50%;
      background: rgba(0,0,0,.2);
      animation: bounce-shadow 2s infinite both;
    }
  }
}

.wave {
  display: inline-block;
  user-select: none;
  margin: 0 .8rem;
}

@media screen and (max-width: 960px) {
  .home {
    flex-direction: column;
    gap: 8rem;
    margin-top: 6rem;
    text-align: center;
    padding: 2rem;

    &-dev {
      &-info {
        margin-top: 4rem;
      }  

      &-buttons-container {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .home {
    flex-direction: column;
    gap: 4rem;
    margin-top: 4rem;

    &-illustration {
      & img {
        width: 100%;
      }

      &-shadow {
        left: 20%;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .home {
    padding: 1rem;
  }
}