.skills {
  &-illustration {
    max-width: 48rem;

    &-container {
      margin: 4rem;
      text-align: center;
    }
  }

  &-wrapper-container {
    gap: 4rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
  }

  &-container {
    gap: 4rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &-item {
    &-wrapper {
      position: relative;
    }

    &-dark {
      box-shadow: 0 3px 10px 0 #1f3652;
    }

    width: 250px;
    height: 80px;
    border-radius: 50px;
    background-color: #fff;
    // box-shadow: 0 3px 22px 0 #e9e6ef;
    box-shadow: 0 3px 10px 0 #e9e6ef;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2.4rem;
    padding: 0 2rem 0 3rem;

    &-img {
      height: 70%;
      filter: grayscale(1);
      transition: filter 0.2s;
    }

    &-text {
      font-size: 1.8rem;
      color: black;
    }

    &:hover &-img {
      filter: grayscale(0);
    }
  }
}

@keyframes skill-animation {
  0% {
    transform: translate(-75%, -75%) rotate(45deg);
  }
  50% {
    transform: translate(10%, 10%) rotate(45deg);
  }
  100% {
    transform: translate(0%, 0%) rotate(45deg);
  }
}

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

@mixin black-gradient {
  background: linear-gradient(
    to right,
    rgba(23, 33, 45, 1) 0%,
    rgba(23, 33, 45, 0) 100%
  );
}

$animationSpeed: 20s;

// Animation
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

// Styling
.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 100px;
    position: absolute;
    width: 20vw;
    max-width: 180px;
    z-index: 2;
  }

  &-light {
    &::before,
    &::after {
      @include white-gradient;
    }
  }

  &-dark {
    &::before,
    &::after {
      @include black-gradient;
    }
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    gap: 4rem;
    width: calc(250px * 14);
    margin-top: 1rem;

    &:hover {
      animation-play-state: paused;
    }
  }
}
